<template>
  <div class="w-100 pt-4">
    <custom-scrollbar>
      <b-container
        fluid="xl"
      >
        <div class="d-flex align-items-center mb-3">
          <h3 class="title mb-0">
            История проверки реестров
          </h3>
        </div>

        <div
          v-if="!totalCount && !isLoading"
          class="px-3"
        >
          Ничего не найдено
        </div>

        <b-table
          v-if="totalCount"
          class="bg-white m-0"
          bordered
          :fields="fields"
          :items="items"
          :per-page="0"
          :current-page="currentPage"
        >
          <template #cell(uploadDate)="row">
            {{ formatDate(row.item.uploadDate) }}
          </template>

          <template #cell(legalPerson)="row">
            {{ row.item.legalPerson.name }}
          </template>

          <template #cell(originalFileId)="row">
            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              :disabled="isFileLoading"
              @click="saveImportFile(row.item.originalFileId, formatDate(row.item.uploadDate))"
            >
              Скачать
            </b-button>
          </template>
          <template #cell(errorFileId)="row">
            <b-button
              v-if="row.item.errorFileName"
              variant="warning"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              :disabled="isFileLoading"
              @click="saveImportFile(row.item.errorFileId, row.item.errorFileName)"
            >
              Скачать
            </b-button>

            <template v-else>
              —
            </template>
          </template>
        </b-table>
        <div class="crm-pagination my-4">
          <b-dropdown
            id="dropdown-1"
            :text="`${perPage}`"
            split
            class="m-md-2"
            size="sm"
            split-variant="outline-primary"
            variant="primary"
          >
            <b-dropdown-item @click="tableSizeHandler(10)">
              10
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="tableSizeHandler(25)">
              25
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="tableSizeHandler(50)">
              50
            </b-dropdown-item>
          </b-dropdown>
          <b-pagination
            v-model="currentPage"
            :total-rows="+allCount"
            :per-page="perPage"
            class="mb-0"
            aria-controls="my-table"
            @input="itemsFetch"
          />
        </div>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
import { format } from '@evd3v/date-fns';
import { utc0ToDateWithUserTimezone } from '@/helpers/utils';
// import { dateWithoutTimeZone } from '@/helpers/utils';
import { registryService } from '@/services';
import { showErrorCustomMessage } from '@/helpers/messages';

export default {
  name: 'RegistryCheckHistory',

  data() {
    return {
      isLoading: false,
      isFileLoading: false,
      items: [],
      currentPage: 1,
      allCount: null,
      perPage: 10,
      take: 10,
      fields: [
        {
          key: 'uploadDate',
          label: 'Дата',

        },
        {
          key: 'legalPerson',
          label: 'Юр. лицо',
        },
        {
          key: 'totalServicesCost',
          label: 'Сумма услуг',
          class: 'width-td',
        },
        {
          key: 'originalFileId',
          label: 'Файл реестра',
          class: 'td-button',
        },
        {
          key: 'errorFileId',
          label: 'Файл ошибки',
          class: 'td-button',
        },
      ],
    };
  },
  computed: {
    totalCount() {
      return this.items.length;
    },
    paginationParams() {
      return {
        take: this.take,
        skip: this.offSet,
      };
    },
    offSet() {
      return (this.currentPage - 1) * this.take;
    },
  },
  async mounted() {
    await this.itemsFetch();
  },
  methods: {
    async tableSizeHandler(mount) {
      this.perPage = mount;
      this.take = this.perPage;
      await this.itemsFetch();
    },
    async itemsFetch() {
      this.isLoading = true;
      try {
        const { paginatedCollection, originalCount } = await this.getImportClinicHistory();
        const newItems = paginatedCollection.map((item) => ({ ...item, createdAt: `${item.uploadDate}` }));
        this.items = newItems;
        this.allCount = originalCount;
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    async getImportClinicHistory() {
      try {
        const data = await registryService.getImportHistory(this.paginationParams);
        return data;
      } catch (err) {
        showErrorCustomMessage('Не удалось загрузить историю импорта');
        console.warn(err);
        throw err;
      }
    },
    formatDate(date) {
      // TODO: спросить нужно ли удалять таймзону
      // const newDate = dateWithoutTimeZone(date);
      return format(utc0ToDateWithUserTimezone(date), 'dd.MM.yyyy HH:mm');
    },
    async saveImportFile(id, name) {
      this.isFileLoading = true;
      try {
        await registryService.getFile({ id, name });
      } catch (err) {
        showErrorCustomMessage('Не удалось скачать файл');
        console.warn(err);
        throw err;
      }
      this.isFileLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
 ::v-deep.bg-white .td-button {
    width: 150px;
    text-align: center;
    vertical-align: inherit;
  }

  ::v-deep.bg-white thead th {
    border-bottom-width: 0px;
    vertical-align: inherit;
  }
  ::v-deep.bg-white .width-td {
    width: 150px;
    text-align: center;
    vertical-align: inherit;
  }

  .crm-pagination {
    display: flex;
    align-items: center;
  }
</style>
